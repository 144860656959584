<template>
  <div id="chart">
    <v-skeleton-loader
      v-if="loading"
      type="image"
    />
    <apexchart
      v-else
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import moment from "moment";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
    series() {
      const fc = this.items.map((r) => r.fuel_dispensed);
      const dsc = this.items.map((r) => r.dispenser_sessions_count);
      return [
        {
          name: this.$options.filters.currencyFormat(
            this.$t("fuel_dispensed"),
            this.authUser
          ),
          type: "column",
          data: fc,
        },
        {
          name: "Dispenser Sessions (count)",
          type: "area",
          data: dsc,
        },
      ];
    },
    chartOptions() {
      const dLable = this.items.map((r) => r.date_only);
      return {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        // dataLabels: {
        //   enabled: true,
        //   enabledOnSeries: [1],
        // },

        labels: dLable,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "string",
          labels: {
            formatter: function (value) {
              return moment(value).format("DD/MM");
            },
          },
        },
        yaxis: [
          {
            title: {
              text: this.$options.filters.currencyFormat(
                this.$t("fuel_dispensed"),
                this.authUser
              ),
            },
          },
          {
            opposite: true,
            title: {
              text: "Dispenser Sessions (count)",
            },
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y;
              }
              return y;
            },
          },
        },
      };
    },
  },
};
</script>
